<template>
  <div>
    <h1 class="subtitle is-3 login__title">Личный кабинет</h1>
    <div class="columns">
      <div class="column is-4 is-offset-4" @keydown.enter="submit_form">
        <section>
          <b-field label="Email:">
            <b-input inputmode="email"
                     v-model="creditionals.email"
                     type="email"
                     :class="{'is-danger': !valid_email}"
                     name="email">
            </b-input>
          </b-field>

          <b-field label="Пароль: ">
            <b-input
              type="password" inputmode="password"
              v-model="creditionals.password"
              password-reveal
              :class="{'is-danger': !valid_password}"
              name="password">
            </b-input>
          </b-field>
        </section>
        <div class="login__button">
          <b-button type="is-primary" icon-right="arrow-right" @click="auth()" ref="submitButton">Войти</b-button>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      creditionals: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.AUTH_IS_LOADING;
    },
    valid_email() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.creditionals.email);
    },
    valid_password() {
      return this.creditionals.password.length >= 6;
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.$store.dispatch('AUTH_BY_TOKEN', this.token)
        .then(() => {
          this.$router.push({ path: '/applications' }).catch(()=>{});
        });
    }
  },
  methods: {
    auth() {
      this.$store.dispatch('SET_AUTH_CREDITIONALS', this.creditionals);
      this.$store.dispatch('AUTH')
        .then(() => {
          this.$router.push({ path: '/applications' }).catch(()=>{});
        })
        .catch((error) => {
          const exception = error.response.data.exception;
          switch (exception) {
            case 'UserNotFoundException':
              this.$toast.warning('Пользователь не найден');
              break;
            case 'UserIsBlockedException':
              this.$toast.warning('Пользователь заблокирован');
              break;
            case 'InvalidUserPasswordException':
              this.$toast.warning('Неправильный пароль');
              break;
            default:
              this.$toast.error('Ошибка авторизации');
              break;
          }
        });
    },
    // функция нажатия enter
    submit_form: function(event)  {
      var elementType = event.target.type;

      if (elementType === "email") {
        var passwordInput = document.querySelector('input[name="password"]');
        if (passwordInput) {
          passwordInput.focus();
        }
      } else {
        var button = this.$refs.submitButton;
        if (button) {
          button.$emit('click');
        }
      }

    }
  },
};
</script>

<style>
.login__title {
  text-align: center;
}

.login__button {
  text-align: center;
  margin-top: 30px;
}
</style>
